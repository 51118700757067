import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"

import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import ToolBar from "../../../components/tool-bar"
import PanoContainer from "../../../components/pano-container"
import { Howl } from "howler"
import QuestionModal from "../../../components/question-modal"

import { Vector3 } from "three"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"

export default class BakingACake extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/baking-a-cake/panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(4982.35, 66.95, -286.52)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/the-spotlight/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(3487.91, 2584.44, -2474.74)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Question Marks
    const bakingCakeQuestion = this.createQuestionInfoSpot(
      "I never bake birthday cakes",
      new Vector3(3408.68, 1732.17, 3209.6)
    )

    const savoryCakeQuestion = this.createQuestionInfoSpot(
      "Can a birthday cake be savory?",
      new Vector3(3719.72, 613.14, -3274.78)
    )

    const candlesQuestion = this.createQuestionInfoSpot(
      "I don't care about the candles",
      new Vector3(-465.92, 1027.99, -4865.13)
    )

    const favouriteRecipeQuestion = this.createQuestionInfoSpot(
      "I have a favourite recipe",
      new Vector3(-2417.06, -2614.26, -3505.25)
    )

    const birthdayWithoutCakeQuestion = this.createQuestionInfoSpot(
      "Is a birthday without a cake still a birthday?",
      new Vector3(2289.27, -3846.96, -2216.24)
    )

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(doorInfoSpot)
    mainPanorama.add(emergencyExitSpot)
    mainPanorama.add(bakingCakeQuestion)
    mainPanorama.add(savoryCakeQuestion)
    mainPanorama.add(candlesQuestion)
    mainPanorama.add(favouriteRecipeQuestion)
    mainPanorama.add(birthdayWithoutCakeQuestion)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(4982.35, 66.95, -286.52), viewer)

    viewer.add(mainPanorama)

    // Setup the new Howl.
    this.sound = new Howl({
      src: ["/departments/baking-a-cake/baking-a-cake.mp3"],
    })

    // Play the sound.
    this.sound.play()

    // Fade In
    this.sound.fade(0.0, 0.5, 2000)
  }

  componentWillUnmount() {
    this.sound.fade(0.5, 0.0, 1000)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="Baking A Cake" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <LoadingBar progress={this.state.progress} />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
          muteSound={() => this.sound.mute(!this.sound.mute())}
        />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="Baking A Cake"
        >
          <p>
            In an opinion poll about ‘what makes a birthday a birthday?’ the
            cake stands out. Not just as an edible treat but also as universal
            symbol of the birthday. Don’t forget the candles!
          </p>
          <p>
            The birthday cake seems from an indispensable level. However, more
            and more people tend to discard this ‘ingredient’ from the birthday
            celebration recipe. Or, in the best scenario, there is just room for
            ‘a little piece’. What happened to her aura? Is it too much
            decoration over taste? Did sugar become an enemy in our diets? Do we
            simply not know anymore how to bake a cake? What is the status of
            the cake anno 2020?
          </p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
